import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
// Fetch hashtags and prepare options for the dropdown
const fetchHashtags = async () => {
  try {
    const response = await fetch(`${API_URL}/hashtags`);
    if (!response.ok) {
      throw new Error("Error fetching hashtags");
    }
    const hashtags = await response.json();
    // Map to get both tag and customId
    return hashtags.map((h) => ({
      label: h.tag, // Display property
      value: h.customId, // Value property
    }));
  } catch (error) {
    console.error("Failed to fetch hashtags", error);
  }
};

const fetchHashtagByCustomId = async (customId) => {
  const response = await fetch(`${API_URL}/hashtags/${customId}`);
  if (!response.ok) {
    throw new Error("Could not fetch hashtag, server responded with an error.");
  }
  return await response.json();
};

const createHashtag = async (hashtagData) => {
  const response = await fetch(`${API_URL}/hashtags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(hashtagData),
  });
  if (!response.ok) {
    throw new Error(
      "Could not create hashtag, server responded with an error."
    );
  }
  return await response.json();
};

const updateHashtag = async (customId, hashtagData) => {
  const response = await fetch(`${API_URL}/hashtags/${customId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(hashtagData),
  });
  if (!response.ok) {
    throw new Error(
      "Could not update hashtag, server responded with an error."
    );
  }
  return await response.json();
};

const fetchHashtagGroups = async () => {
  try {
    const response = await fetch(`${API_URL}/hashtag-groups`);
    if (!response.ok) {
      throw new Error(`Failed to fetch groups: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching groups:", error);
    throw error;
  }
};

const deleteHashtag = async (customId) => {
  const response = await fetch(`${API_URL}/hashtags/${customId}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    throw new Error(
      "Could not delete hashtag, server responded with an error."
    );
  }
  return await response.json(); // or just return response.ok if you don't expect a JSON response
};

export const fetchItemsByHashtag = async (hashtagIds) => {
  const response = await fetch(
    `${API_URL}/tasks/by-hashtags?hashtags=${hashtagIds}`
  );
  if (!response.ok) {
    throw new Error(`Error fetching tasks: ${response.statusText}`);
  }
  return await response.json();
};

const saveHashtag = async (customId, hashtagData) => {
  if (customId) {
    return await updateHashtag(customId, hashtagData);
  } else {
    return await createHashtag(hashtagData);
  }
};

const createOrganization = async (name) => {
  try {
    const response = await fetch(`${API_URL}/organizations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    });
    if (!response.ok) {
      throw new Error("Failed to create organization");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating organization:", error);
    throw error;
  }
};

export const fetchOrganizations = async () => {
  try {
    console.log("Fetching organizations...");
    const response = await fetch(`${API_URL}/organizations`);
    if (!response.ok) {
      throw new Error("Failed to fetch organizations");
    }
    const data = await response.json();
    console.log("Organizations fetched successfully:", data);
    return data;
  } catch (error) {
    console.error("Error fetching organizations:", error);
    throw error;
  }
};

// Fetch hashtags by team
export const fetchHashtagsByTeam = async (teamId) => {
  try {
    const url = `${API_URL}/teams/${teamId}/hashtags`;
    console.log("Fetching hashtags from URL:", url); // Log the full URL
    const response = await fetch(url);

    if (!response.ok) {
      const errorText = await response.text(); // Read the response text in case of an error
      console.error("Response error text:", errorText);
      throw new Error(
        `Failed to fetch hashtags for team. Status: ${response.status}`
      );
    }

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      console.error("Expected JSON response, but received:", contentType);
      throw new Error("Server did not return JSON data.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching hashtags by team:", error);
    throw error;
  }
};
// Fetch teams by organization
// export const fetchTeamsByOrg = async (orgId) => {
//   try {
//     console.log(`Fetching teams for organization ID: ${orgId}`);
//     const response = await fetch(`${API_URL}/organizations/${orgId}/teams`);
//     if (!response.ok) {
//       throw new Error("Failed to fetch teams for organization");
//     }
//     const data = await response.json();
//     console.log("Teams fetched successfully:", data);
//     return data;
//   } catch (error) {
//     console.error("Error fetching teams:", error);
//     throw error;
//   }
// };
export const fetchTeamsByOrg = async (orgId) => {
  try {
    console.log(`Fetching teams for organization ID: ${orgId}`);
    const response = await fetch(`${API_URL}/organizations/${orgId}/teams`);
    if (!response.ok) {
      throw new Error("Failed to fetch teams for organization");
    }
    const teams = await response.json();

    // Ensure "Uncategorized" is present in the list
    const uncategorizedTeam = {
      _id: "uncategorized",
      name: "Uncategorized",
    };
    console.log("Teams fetched successfully:", teams);

    return [uncategorizedTeam, ...teams]; // Add "Uncategorized" to the beginning
  } catch (error) {
    console.error("Error fetching teams:", error);
    throw error;
  }
};

export const addTeamToOrganization = async (orgId, teamName) => {
  try {
    console.log("Adding team to organization:", { orgId, teamName });
    const response = await fetch(`${API_URL}/organizations/${orgId}/teams`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: teamName }),
    });
    if (!response.ok) {
      throw new Error("Failed to add team to organization");
    }
    const data = await response.json();
    console.log("Team added successfully:", data);
    return data;
  } catch (error) {
    console.error("Error adding team to organization:", error);
    throw error;
  }
};
// In HashtagServices.js

const addHashtagToTeam = async (teamId, hashtagData) => {
  const url = `${API_URL}/teams/${teamId}/hashtags`;
  console.log("API Call: Adding hashtag to team", { url, hashtagData });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(hashtagData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error Response:", response.status, errorText);
      throw new Error(
        `Failed to add hashtag to team. Status: ${response.status}.`
      );
    }

    const data = await response.json();
    console.log("Hashtag added successfully:", data);
    return data;
  } catch (error) {
    console.error("Error in addHashtagToTeam:", error.message);
    throw error;
  }
};

export async function fetchUncategorizedHashtags() {
  try {
    const response = await fetch(`${API_URL}/hashtags/uncategorized`);
    if (!response.ok) {
      throw new Error("Failed to fetch uncategorized hashtags");
    }
    const data = await response.json();
    console.log("Uncategorized hashtags fetched successfully:", data);
    return data.hashtags; // Adjust if your API structure changes
  } catch (error) {
    console.error("Error fetching uncategorized hashtags:", error);
    throw error;
  }
}

const updateTeamName = async (teamId, newName) => {
  try {
    console.log(`Updating team ID ${teamId} to name: ${newName}`);
    const response = await axios.put(`${API_URL}/teams/${teamId}`, {
      name: newName,
    });
    if (!response.status === 200) {
      throw new Error("Failed to update team name.");
    }
    console.log("Team updated successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating team name:", error);
    throw error;
  }
};
const assignHashtagToTeam = async (teamId, data) => {
  const url = `${API_URL}/teams/${teamId}/assign-hashtag`; // Correctly build the URL
  console.log("Making API call to assign hashtag to team:", {
    teamId,
    url,
    data,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error Response:", response.status, errorData);
      throw new Error(
        `Error: ${response.status}, Message: ${
          errorData.message || "Unknown error"
        }`
      );
    }

    const result = await response.json();
    console.log("API call successful. Response:", result);
    return result;
  } catch (error) {
    console.error("Error in assignHashtagToTeam:", error.message || error);
    throw error;
  }
};

const HashtagService = {
  fetchHashtags,
  fetchHashtagByCustomId,
  createHashtag,
  updateHashtag,
  deleteHashtag,
  updateTeamName,
  fetchItemsByHashtag,
  saveHashtag,
  fetchHashtagsByTeam, // Keep only one instance
  fetchTeamsByOrg,
  fetchOrganizations,
  assignHashtagToTeam,
  createOrganization,
  addTeamToOrganization,
  addHashtagToTeam,
  fetchUncategorizedHashtags,
};

export default HashtagService;
