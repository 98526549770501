import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import MainComponent from "./MainComponent";
import Login from "../Login/Login";
import Signup from "../Login/SignUp";
import ForgotPassword from "../Login/ForgotPassword";
import Logout from "../Login/Logout";
import ItemDetail from "./ItemDetail";
import HashtagList from "../Hashtags/sub-components/HashtagList";
import HashtagTasksPage from "../Hashtags/sub-components/HashtagTasksPage";
import HashtagGroupsPage from "../Hashtags/HashtagGroupsPage"; // New component for managing groups
import SettingsPage from "../Hashtags/sub-components/SettingsPage";
import GanttChartView from "../Timeline/GanttChartView";
import SuperAdminPage from "../SuperAdmin/SuperAdminPage";
import ProtectedRoute from "../ProtectedRoute";
import ResetPassword from "../Login/ResetPassword";
import "bootstrap/dist/css/bootstrap.min.css";

const AppRoutes = ({ isUserLoggedIn, ...props }) => {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem("userRole");
    setUserRole(role);
  }, []);

  return (
    <Routes>
      {isUserLoggedIn ? (
        <>
          <Route path="/" element={<Navigate replace to="/main" />} />
          <Route path="/main/*" element={<MainComponent {...props} />} />
          <Route path="/main" element={<MainComponent {...props} />} />

          {/* Route for viewing all hashtag groups */}
          <Route
            path="/hashtag-groups"
            element={<HashtagGroupsPage {...props} />}
          />

          {/* Route for viewing hashtags within a selected group */}
          <Route
            path="/hashtag-groups/:groupId"
            element={<HashtagList {...props} />}
          />

          {/* Route for managing tasks within a specific hashtag */}
          <Route
            path="/hashtags/:id"
            element={<HashtagTasksPage {...props} />}
          />

          {/* Other routes */}
          <Route path="/task/:itemId" element={<ItemDetail {...props} />} />
          <Route path="/timeline" element={<GanttChartView />} />
          <Route path="/logout" element={<Logout />} />

          {/* Protected route for superadmin */}
          <Route
            path="/superadmin"
            element={
              <ProtectedRoute
                allowedRoles={["superadmin"]}
                userRole={userRole}
                element={<SuperAdminPage />}
              />
            }
          />
          <Route path="/settings" element={<SettingsPage />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
