// ItemServices.js
const API_URL = process.env.REACT_APP_API_URL;

const fetchItems = async (selectedHashtags) => {
  const customIds = selectedHashtags.map((ht) => ht.customId).join(",");
  const response = await fetch(`${API_URL}/items?hashtags=${customIds}`);
  if (!response.ok) {
    throw new Error("Error fetching items");
  }
  return await response.json();
};

// ItemServices.js
export const fetchItem = async (itemId) => {
  const response = await fetch(`${API_URL}/items/${itemId}`);
  if (!response.ok) {
    throw new Error(
      `Error fetching item with ID ${itemId}: ${response.statusText}`
    );
  }
  return await response.json();
};

export const fetchItemsByHashtags = async (hashtags) => {
  console.log("Hashtags received:", hashtags); // Check what hashtags are being passed

  if (!hashtags || !hashtags.length) {
    console.error("No hashtags provided for API call");
    return []; // Return an empty array or handle the error as appropriate
  }

  const joinedHashtagIds = hashtags.join(",");
  const apiUrl = `${API_URL}/tasks/by-hashtags?hashtags=${joinedHashtagIds}`;
  console.log("Requesting API at URL:", apiUrl); // Log the exact URL being requested

  const response = await fetch(apiUrl);

  if (!response.ok) {
    throw new Error(`Error fetching tasks: ${response.statusText}`);
  }

  return await response.json();
};
