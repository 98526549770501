import React from "react";
import AddItem from "../../Tasks/sub-components/AddItem";
import HashtagManager from "./HashtagManager";
import "../styles/HashtagAndItemManager.css";

function HashtagAndItemManager({
  selectedHashtags,
  onItemAdded,
  setSelectedHashtags,
}) {
  return (
    <div className="hashtag-and-item-manager">
      {/* AddItem component: Handles adding new items with selected hashtags */}
      {/* <AddItem selectedHashtags={selectedHashtags} onItemAdded={onItemAdded} /> */}
      {/* HashtagManager component: Manages hashtags and hashtag groups */}
      <HashtagManager
        selectedHashtags={selectedHashtags}
        setSelectedHashtags={setSelectedHashtags}
      />{" "}
    </div>
  );
}

export default HashtagAndItemManager;
