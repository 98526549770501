import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "../styles/Modal.css";

const Modal = ({ onClose, children }) => {
  const modalRoot = document.getElementById("modal-root");

  // Log an error if the modal root is not found
  if (!modalRoot) {
    console.error("Error: 'modal-root' element not found in index.html");
  }

  // useEffect hook runs every time the modal is mounted/unmounted
  useEffect(() => {
    console.log("Modal mounted");
    return () => {
      console.log("Modal unmounted");
    };
  }, []);

  // If the modalRoot is not available, return null after the useEffect
  if (!modalRoot) {
    return null;
  }

  console.log("Rendering Modal...");

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>
          &times; {/* Close button */}
        </button>
        {children}
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;
