import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setStartDate, setEndDate, addDateComment } from "../../redux/actions";
import "./ActionPanel.css";

const ETAComponent = ({ itemId }) => {
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/items/${itemId}`
        );
        if (response.ok) {
          const data = await response.json();
          setLocalStartDate(data.start_date ? new Date(data.start_date) : null);
          setLocalEndDate(data.end_date ? new Date(data.end_date) : null);
          dispatch(
            setStartDate(data.start_date ? new Date(data.start_date) : null)
          );
          dispatch(setEndDate(data.end_date ? new Date(data.end_date) : null));
        } else {
          console.error("Failed to fetch task details");
        }
      } catch (error) {
        console.error("Error fetching task details:", error);
      }
    };

    if (itemId) {
      fetchTaskDetails();
    }
  }, [itemId, dispatch]);

  const handleStartDateChange = async (date) => {
    setLocalStartDate(date);
    dispatch(setStartDate(date));
    dispatch(
      addDateComment({
        text: `Start date set: ${date.toLocaleDateString()}`,
        postedBy: "System",
        postedAt: new Date(),
      })
    );
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/items/${itemId}/saveStartDate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startDate: date,
            user: localStorage.getItem("username"),
          }),
        }
      );
      if (!response.ok) {
        console.error("Failed to update start date");
      }
    } catch (error) {
      console.error("Error updating start date:", error);
    }
  };

  const handleEndDateChange = async (date) => {
    setLocalEndDate(date);
    dispatch(setEndDate(date));
    dispatch(
      addDateComment({
        text: `End date set: ${date.toLocaleDateString()}`,
        postedBy: "System",
        postedAt: new Date(),
      })
    );
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/items/${itemId}/saveEndDate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            endDate: date,
            user: localStorage.getItem("username"),
          }),
        }
      );
      if (!response.ok) {
        console.error("Failed to update end date");
      }
    } catch (error) {
      console.error("Error updating end date:", error);
    }
  };

  return (
    <div className="date-input-wrapper">
      <div>
        <label>Start Date:</label>
        <DatePicker
          selected={localStartDate}
          onChange={(date) => handleStartDateChange(date)}
          className="date-input"
        />
      </div>
      <div>
        <label>End Date:</label>
        <DatePicker
          selected={localEndDate}
          onChange={(date) => handleEndDateChange(date)}
          className="date-input"
        />
      </div>
    </div>
  );
};

export default ETAComponent;

//wich will work on the comments
// import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { useDispatch, useSelector } from "react-redux";
// import { setStartDate, setEndDate, addDateComment } from "../../redux/actions";
// import "./ActionPanel.css";

// const ETAComponent = ({ itemId }) => {
//   const [localStartDate, setLocalStartDate] = useState(null);
//   const [localEndDate, setLocalEndDate] = useState(null);
//   const dispatch = useDispatch();

//   const reduxState = useSelector((state) => state);
//   console.log("Complete Redux State:", reduxState);

//   const datesState = useSelector(
//     (state) => state.dates || { startDate: null, endDate: null }
//   );
//   const { startDate, endDate } = datesState;

//   console.log("Dates from Redux:", datesState);

//   useEffect(() => {
//     if (localStartDate && localStartDate !== startDate) {
//       dispatch(setStartDate(localStartDate));
//     }
//     if (localEndDate && localEndDate !== endDate) {
//       dispatch(setEndDate(localEndDate));
//     }
//   }, [localStartDate, localEndDate, startDate, endDate, dispatch]);

//   return (
//     <div className="date-input-wrapper">
//       <div>
//         <label htmlFor="startDate">Start Date:</label>
//         <DatePicker
//           id="startDate"
//           selected={localStartDate}
//           onChange={(date) => setLocalStartDate(date)}
//           className="date-input"
//         />
//       </div>

//       <div>
//         <label htmlFor="endDate">End Date:</label>
//         <DatePicker
//           id="endDate"
//           selected={localEndDate}
//           onChange={(date) => setLocalEndDate(date)}
//           className="date-input"
//         />
//       </div>
//     </div>
//   );
// };

// export default ETAComponent;
